
import { Options, Vue } from "vue-class-component";
import { ApplicationStatus } from "@/middlewares/enum";
import ApprovalReport from "./ApprovalReport.vue";
import { Notify, Toast } from "vant";
import moment from "moment";
@Options({
  components: {
    [Notify.Component.name]: Notify.Component,
    ApprovalReport,
  },
})
export default class NoExamineReportlist extends Vue {
  private applicationStatus = ApplicationStatus;
  private refreshing = false;
  private show = false;
  private passing = false;
  private returning = false;
  private approvalForm: any = {
    id: "",
    applicationIds: [],
    approver: "",
    approveTime: "",
    approvalOpinion: "",
    status: "",
    remark: "",
    checkOrgId: "",
  };
  private loading = false;
  private finished = false;
  private applyList: any = [];
  private pageIndex = 1;
  private allChecked = false;
  private searchDateType = "today";
  private serchForm: any = {
    sampleCode: "",
    sampleName: "",
  };
  private findApplyList() {
    if (this.refreshing) {
      this.applyList = [];
      this.refreshing = false;
    }
    this.loading = true;
    const url = "/api/lims/application/getAppPageList";
    this.axios
      .get(url, {
        params: {
          sampleCode: this.serchForm.sampleCode,
          sampleName: this.serchForm.sampleName,
          appStatus: "in:50,51",
          size: 20,
          current: this.pageIndex,
          approvalStatus: "51",
          isShowapprovalName: "1",
        },
      })
      .then((res: any) => {
        //关闭加载
        this.loading = false;
        if (res.status.code !== 1) {
          this.$toast.fail(res.status.msg);
        } else {
          for (const record of res.data.records) {
            record.checked = false;
          }
          this.applyList = this.applyList.concat(res.data.records);
        }
        if (
          !res.data.records ||
          res.data.records.length === 0 ||
          this.applyList.length === res.data.total
        ) {
          this.finished = true;
        } else {
          this.pageIndex++;
        }
      })
      .catch((err) => {
        this.$toast.fail(err);
      });
  }

  private submitForm() {
    this.approvalForm.applicationIds = [];
    for (const sp of this.applyList) {
      if (sp.checked) {
        this.approvalForm.applicationIds.push(sp.id);
      }
    }
    if (this.approvalForm.applicationIds.length === 0) {
      Toast("请选择需要审核的单据");
      return;
    }
    this.approvalForm.approver = this.$store.state.userInfo.id;
    this.approvalForm.approveTime = moment().format("YYYY-MM-DD");
    this.approvalForm.approvalOpinion = "报告审核";
    this.approvalForm.checkOrgId = this.$store.state.userInfo.orgId;
    this.show = true;
  }
  //审核通过
  private examinePass() {
    this.passing = true;
    this.approvalForm.status = "55";
    this.doApproval();
  }
  //审核退回
  private examinReturn() {
    this.returning = true;
    this.approvalForm.status = "46";
    this.doApproval();
  }
  private doApproval() {
    const url = "/api/lims/applicationApproval/applicationReportBatchApproval";
    this.axios
      .post(url, this.approvalForm)
      .then((res: any) => {
        if (res.status.code === 1) {
          Toast("审核成功");
          this.doSearch();
          this.show = false;
          this.approvalForm = {
            id: "",
            applicationIds: [],
            approver: "",
            approveTime: "",
            approvalOpinion: "",
            status: "",
            remark: "",
            checkOrgId: "",
          };
        } else {
          Notify(res.status.msg);
        }
        this.passing = false;
        this.returning = false;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  private closePopup() {
    this.approvalForm = {
      id: "",
      applicationIds: [],
      approver: "",
      approveTime: "",
      approvalOpinion: "",
      status: "",
      remark: "",
      checkOrgId: "",
    };
  }
  private doSearch() {
    this.refreshing = true;
    this.allChecked = false;
    this.onRefresh();
    const ref: any = this.$refs["dropdownSearch"];
    ref.toggle(false);
  }

  private getApplicationStatusDesc(scope: any) {
    for (const item of this.applicationStatus) {
      if (scope === item.code) {
        return item.name;
      }
    }
  }

  private formatDataTime(time: any) {
    return moment(time).format("YYYY-MM-DD");
  }
  private checkAllChange(event: MouseEvent) {
    for (const sp of this.applyList) {
      sp.checked = this.allChecked;
    }
  }
  private checkOneChange(event: MouseEvent) {
    let isAllCheck = true;
    for (const sp of this.applyList) {
      if (!sp.checked) {
        isAllCheck = false;
        break;
      }
    }
    this.allChecked = isAllCheck;
  }
  //下拉刷新
  private onRefresh() {
    // 清空列表数据
    this.finished = false;
    this.pageIndex = 1;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.findApplyList();
  }
  //重置查询
  private resetSearch() {
    this.searchDateType = "today";
    this.serchForm = {
      sampleCode: "",
      sampleName: "",
    };
    this.refreshing = true;
    this.onRefresh();
    const ref: any = this.$refs["dropdownSearch"];
    ref.toggle(false);
  }

  private showApproval(item: any) {
    //打开样本详情
    const temp: any = this.$refs.approvalReportRef;
    temp.showApproval(item);
  }
}
